import React from 'react'
import { Navigation, TenantContextWrapper, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import { navs } from './navs'
import LocaleWrapper from './common/locale/LocaleWrapper'

export const App = () => {
  const { activeTenant } = useTenants()
  return (
    <LocaleWrapper>
      <TenantContextWrapper>
        <Navigation navigation={[...navs]} dependencies={{ activeTenant: activeTenant! }} />
      </TenantContextWrapper>
    </LocaleWrapper>
  )
}
