import { Navigation, requiresTenant, requiresOneOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { Email as EmailIcon } from '@griegconnect/krakentools-react-icons'
import React from 'react'
import { PATH_TENANT_BASE, Permissions, TENANT_PERMISSIONS } from '../../../constants'
import { AccessDependencies } from '../../../'
import { Trans } from 'react-i18next'

const Invitations = React.lazy(() => import('./List'))

export const invitationsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.pendingInvitations" />,
    page: <Invitations />,
    path: PATH_TENANT_BASE + '/invitations',
    exact: true,
    menu: {
      icon: <EmailIcon />,
    },
    access: (d) => requiresTenant(d) && requiresOneOfPermissions(Permissions.all(TENANT_PERMISSIONS.company))(d),
  },
]
