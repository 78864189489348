import { useLocation } from 'react-router-dom'

type IData<ObjectType> = {
  [PropertyType in keyof ObjectType]: ObjectType[PropertyType] extends number ? string : ObjectType[PropertyType]
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useUrlQueryParams<Params extends { [K in keyof Params]?: string } = {}>(): IData<Params> {
  const urlParams = new URLSearchParams(useLocation().search)
  const result: IData<any> = {}
  for (const [key, value] of urlParams.entries()) {
    // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return result
}
