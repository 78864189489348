import { Navigation } from '@griegconnect/krakentools-react-kraken-app'
import { AccessDependencies } from './'
import { homeModuleNavs } from './modules/Home/homeModuleNavs'
import { companyModuleNavs } from './modules/Company/companyModuleNavs'
import { invitationsModuleNavs } from './modules/Company/Invitations/invitationsModuleNavs'
import { integrationModuleNavs } from './modules/Integrations/integrationModuleNavs'

export const navs: Navigation.Item<AccessDependencies>[] = [
  ...homeModuleNavs,
  ...companyModuleNavs,
  ...invitationsModuleNavs,
  ...integrationModuleNavs,
]
