import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgVisibilityOn = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z" />,
    <path d="M12 4C9.135 4 6.403 5.694 4.47 7.37a18.238 18.238 0 0 0-2.4 2.5 8.865 8.865 0 0 0-.716 1.05c-.09.158-.17.32-.23.48A1.7 1.7 0 0 0 1 12c0 .243.069.459.123.6.061.16.142.322.23.48.178.315.424.673.717 1.05a18.24 18.24 0 0 0 2.4 2.5C6.403 18.307 9.135 20 12 20s5.597-1.694 7.53-3.37a18.24 18.24 0 0 0 2.4-2.5c.293-.377.539-.736.716-1.05.09-.158.17-.32.23-.48.055-.141.124-.357.124-.6s-.069-.459-.123-.6a3.628 3.628 0 0 0-.23-.48 8.86 8.86 0 0 0-.717-1.05 18.236 18.236 0 0 0-2.4-2.5C17.597 5.693 14.865 4 12 4Zm-8.904 7.903c.115-.204.299-.478.553-.805a16.246 16.246 0 0 1 2.13-2.217C7.598 7.306 9.866 6 12 6s4.403 1.306 6.22 2.88c.89.772 1.626 1.568 2.131 2.218.254.327.438.601.553.805l.052.097a2.63 2.63 0 0 1-.052.097c-.115.204-.299.478-.553.805a16.251 16.251 0 0 1-2.13 2.217C16.402 16.694 14.134 18 12 18s-4.403-1.306-6.22-2.88a16.246 16.246 0 0 1-2.131-2.218 6.919 6.919 0 0 1-.553-.805A2.686 2.686 0 0 1 3.044 12c.014-.028.031-.06.052-.097Z" />
  )
export default SvgVisibilityOn
