import { Tenant } from '@griegconnect/krakentools-react-kraken-app'

export const PATH_TENANT_BASE = '/companies/:tenantRef'
export namespace Permissions {
  export const all = (p: { [k: string]: Tenant.Permission }): Tenant.Permission[] => Object.values(p)

  type Create = { create: Tenant.Permission }
  type Read = { read: Tenant.Permission }
  type Update = { update: Tenant.Permission }
  type Delete = { delete: Tenant.Permission }

  type Write = { write: Tenant.Permission }

  export const _create = (domain: string, name: string): Create => ({ create: { domain, name: `${name}:create` } })
  export const _read = (domain: string, name: string): Read => ({ read: { domain, name: `${name}:read` } })
  export const _update = (domain: string, name: string): Update => ({ update: { domain, name: `${name}:update` } })
  export const _delete = (domain: string, name: string): Delete => ({ delete: { domain, name: `${name}:delete` } })

  export const _write = (domain: string, name: string): Write => ({ write: { domain, name: `${name}:write` } })

  export const _crud = (domain: string, name: string) => ({
    ...Permissions._create(domain, name),
    ...Permissions._read(domain, name),
    ...Permissions._update(domain, name),
    ...Permissions._delete(domain, name),
  })

  export const cru = (domain: string, name: string) => ({
    ...Permissions._create(domain, name),
    ...Permissions._read(domain, name),
    ...Permissions._update(domain, name),
  })
}

export const TENANT_PERMISSIONS = {
  company: { ...Permissions._crud('company', 'users') },
  clients: { ...Permissions._read('company', 'clients') },
}

export const POSTIE_PERMISSIONS = {
  webhooks: { ...Permissions._read('postie', 'webhooks'), ...Permissions._write('postie', 'webhooks') },
}
