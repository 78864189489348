import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgVisibilityOff = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M3.707 2.293a1 1 0 0 0-1.414 1.414l2.993 2.994c-.285.22-.558.444-.816.668a18.237 18.237 0 0 0-2.4 2.501 8.867 8.867 0 0 0-.716 1.05c-.09.158-.17.32-.23.48A1.701 1.701 0 0 0 1 12c0 .242.069.459.123.6.061.16.142.322.23.48.178.314.424.673.717 1.05a18.235 18.235 0 0 0 2.4 2.5C6.403 18.307 9.135 20 12 20c1.782 0 3.513-.655 5.032-1.554l3.26 3.261a1 1 0 0 0 1.415-1.414l-18-18Zm11.857 14.685C14.4 17.601 13.18 18 12 18c-2.135 0-4.403-1.306-6.22-2.88a16.246 16.246 0 0 1-2.131-2.218 6.927 6.927 0 0 1-.553-.805A2.686 2.686 0 0 1 3.044 12c.014-.028.031-.06.052-.097.115-.204.299-.478.553-.805a16.246 16.246 0 0 1 2.13-2.217c.3-.26.613-.513.935-.753l1.84 1.84a4 4 0 0 0 5.478 5.478l1.532 1.532Zm-5.496-5.496 2.45 2.45a2.001 2.001 0 0 1-2.45-2.45ZM11.451 8.037l4.512 4.511a4 4 0 0 0-4.511-4.511Z" />,
    <path d="M20.351 12.902a16.096 16.096 0 0 1-1.964 2.07l1.416 1.417a17.997 17.997 0 0 0 2.127-2.26c.293-.376.539-.735.716-1.05.09-.157.17-.319.23-.478.055-.143.124-.359.124-.601 0-.243-.069-.459-.123-.6a3.633 3.633 0 0 0-.23-.48 8.861 8.861 0 0 0-.717-1.05 18.236 18.236 0 0 0-2.4-2.5C17.597 5.693 14.865 4 12 4c-1.286 0-2.545.341-3.717.869l1.536 1.536C10.546 6.148 11.281 6 12 6c2.135 0 4.403 1.306 6.22 2.88.89.772 1.626 1.568 2.131 2.218.254.327.438.601.553.805l.052.097a2.63 2.63 0 0 1-.052.097c-.115.204-.299.478-.553.805Z" />
  )
export default SvgVisibilityOff
