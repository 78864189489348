import { Navigation, requiresTenant, requiresOneOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { Connectors } from '@griegconnect/krakentools-react-icons'
import React from 'react'
import { PATH_TENANT_BASE, Permissions, POSTIE_PERMISSIONS, TENANT_PERMISSIONS } from '../../constants'
import { AccessDependencies } from '../..'
import { Trans } from 'react-i18next'

const ClientsList = React.lazy(() => import('./Integrations'))

export const integrationModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.integrations" />,
    page: <ClientsList />,
    path: PATH_TENANT_BASE + '/integrations',
    exact: true,
    menu: {
      icon: <Connectors />,
    },
    access: (d) =>
      requiresTenant(d) &&
      requiresOneOfPermissions([
        ...Permissions.all(TENANT_PERMISSIONS.clients),
        ...Permissions.all(POSTIE_PERMISSIONS.webhooks),
      ])(d),
  },
]
