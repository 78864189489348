import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgAgenda = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M11 10a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1ZM10 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM11 13a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1ZM10 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM11 16a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1ZM10 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />,
    <path d="M9.18 3C9.6 1.84 10.7 1 12 1c1.3 0 2.4.84 2.82 2H18c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h3.18ZM18 19V5h-2v1a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V5H6v14h12ZM12 5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1Z" />
  )
export default SvgAgenda
