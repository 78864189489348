import { Navigation, requiresTenant } from '@griegconnect/krakentools-react-kraken-app'
import { Dashboard as DashboardIcon } from '@griegconnect/krakentools-react-icons'
import { Trans } from 'react-i18next'
import React from 'react'
import { PATH_TENANT_BASE } from '../../constants'
import { AccessDependencies } from '../../'

const Dashboard = React.lazy(() => import('./index'))

export const homeModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.dashboard" />,
    page: <Dashboard />,
    path: PATH_TENANT_BASE,
    exact: true,
    menu: {
      icon: <DashboardIcon />,
    },
    access: (d) => requiresTenant(d),
  },
]
