import {
  Navigation,
  requiresTenant,
  requiresOneOfPermissions,
  requiresPermission,
} from '@griegconnect/krakentools-react-kraken-app'
import { Permit as PermitIcon } from '@griegconnect/krakentools-react-icons'
import { Trans } from 'react-i18next'
import React from 'react'
import { PATH_TENANT_BASE, TENANT_PERMISSIONS, Permissions } from '../../constants'
import { AccessDependencies } from '../../'

const Users = React.lazy(() => import('./Roles/List'))
const AddUser = React.lazy(() => import('./Roles/Add'))
const EditUser = React.lazy(() => import('./Roles/Edit'))

export const PATH_USERS = PATH_TENANT_BASE + '/userroles'
export const companyModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.users.users" />,
    page: <Users />,
    path: PATH_USERS,
    menu: {
      icon: <PermitIcon />,
    },
    access: (d) => requiresTenant(d) && requiresOneOfPermissions(Permissions.all(TENANT_PERMISSIONS.company))(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.users.inviteUsers" />,
        page: <AddUser />,
        path: PATH_TENANT_BASE + '/userroles/add',
        access: (d) => requiresTenant(d) && requiresPermission(TENANT_PERMISSIONS.company.update)(d),
      },
      {
        name: <Trans i18nKey="navigation.users.editUserRoles" />,
        page: <EditUser />,
        path: PATH_TENANT_BASE + '/userroles/edit/:userEmail',
        access: (d) => requiresTenant(d) && requiresPermission(TENANT_PERMISSIONS.company.update)(d),
      },
    ],
  },
]
