import { generatePath as generatePathReactRouter } from 'react-router-dom'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { useRecoilValue } from 'recoil'
import { activeTenantSelector } from '../atoms/tenantsAtom'

export const usePath = () => {
  const { tenantsUrlMatchPathParam } = useApplicationOptions()
  const activeTenant = useRecoilValue(activeTenantSelector)

  /**
   * @example
   * generatePath('/companies/:tenantRef/users'), will return /companies/griegconnect/users
   */
  const generatePath = (path: string) => {
    if (activeTenant) {
      return generatePathReactRouter(path, {
        [tenantsUrlMatchPathParam]: activeTenant.ref,
      })
    } else {
      return path
    }
  }

  return {
    generatePath,
  }
}
